html body {
   overflow-x: hidden;
   margin: 0;
}

.App {
  text-align: center;
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
}


::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-thumb {
  background: rgb(169, 174, 178); 
  border-radius: none;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(14, 148, 51, 1);
}




[data-aos] {
  pointer-events: none;
}
.aos-animate {
  pointer-events: auto;
}
.aos-init[data-aos][data-aos].aos-animate {
  transform: unset;
}


h1, .secondTitle {
  color: rgba(14, 148, 51, 1);
}

h1 {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: rgba(14, 148, 51, 1);
}

.flo {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://raw.githubusercontent.com/florentdesmarets/Image-storage/main/8-bit-gif-background/Obi-Wan-Kenobi-Hello-There.avif');
}

.flotxt {
  font-size:400px;
  color: white;
  animation: troll infinite 1s ;
}



@keyframes troll {
  0% {
    transform: scale(0.1)
  };
  100% {
    transform: scale(1.6);
  }
}

.navbar {
  background-color: white;
  min-height: 10vh;
  position: fixed;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  top: 0;
}

.link {
  text-decoration: none;
}


.buttons {
  font-size: 1rem;
  color: rgba(14, 148, 51, 1);
  border: 2px solid rgba(14, 148, 51, 1);
  padding:8px;
  cursor:pointer;
  margin: 0.8rem;
  min-width: 270px;

}

.buttons:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

.buttons:active {
  transform: scale(0.95);
}

.basic-burger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor:pointer;
  position: relative;
  padding:10px;
  margin-left: 14vw;
  margin-right: 10vw;
}

.logo {
  margin-left: 10vw;
  margin-right: 10vw;
}

.logo2 {
  width: 150px;
  height: 150px;
}

.logo3 {
  width: 150px;
  height: 150px;
  padding: 20px;
}


.logo2:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

.logo2:active {
  transform: scale(0.9);
}

.basic-burger span {
  display: flex;
  background-color: rgba(14, 148, 51, 1);
  height: 5px;
  width: 40px;
  margin-bottom: 8px;
  z-index: 20;
}

.menu-close {
  display:none
}
.menu-open {
   display: flex;
  position: fixed;
   align-items: center;
   top: 0vh;
   right: 0;
   width: 100vw;
   height: 100vh;
   flex-direction: column;
   background: rgb(255, 255, 255, .9);
   color:  rgba(14, 148, 51) ;
   transition: all 0.5s ease-in-out;
   animation-name: transitionOpen;
   animation-duration: 1s;
   animation-iteration-count: inherit;
   animation-fill-mode: backwards;
   border-left: 100px solid white;
   text-align: center;
}

.basic-links-container {
   justify-content: center;
   flex-direction: column;
   align-items: center;
   display: flex;
   width: 80vw;
   height: 60vh;
   margin-top:20vh;
}
@keyframes transitionOpen {
   0% {
       transform: translate(300%, 0);
   }
   100% {
       transform: translate(0%, 0%);
   }
}

@keyframes transitionClose {
   0% {
       transform: translate(0%, 0%);
   }
   100% {
       transform: translate(300%, 0%);
   }
}

.cross-2 {
   transform: rotate(315deg) translate(-10px, -10px);
   transition: all 0.5s ease-in-out;
   height: 0px;
   padding: 1.5px;
}
.cross-1 {
   transform: rotate(45deg) translate(0px, 22px);
   transition: all 0.5s ease-in-out;
   padding: 1.5px;
}
.cross-3 {
   transform: rotate(-45deg) translate(0px, -20px);
   transition: all 0.5s ease-in-out;
}
.cross-4 {
   transform: translate(0px, 0px);
   transition: all 0.5s ease-in-out;
}
.cross-6 {
   transform: translate(0px, 0px);
   transition: all 0.5s ease-in-out;
}


.backgroundContainer1{
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
}

.paralax1 {
  background-image: url('https://github.com/florentdesmarets/Image-storage/blob/main/8-bit-gif-background/first%20bg.jpg?raw=true');
}


.backgroundContainer2{
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
}

.backgroundContainer3{
  min-height: 60vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items:center;
  justify-content: space-between;
  flex-direction: column;
}


.paralax2 {
  background-image: url('./assets/gros-plan-germes-sol.jpg');
}

.paralax3 {
  background-image: url('./assets/young-women-with-open-arms-happily\ \(1\).jpg');
}

.paralax4 {
  background-image: url('./assets/top-view-natural-medicinal-spices-herbs.jpg');
}

.paralax5 {
  background-image: url('./assets/high-angle-natural-medicine-concept.jpg');
}

.paralax6 {
  background-image: url('./assets/closeup-shot-small-green-leaves-bush.jpg');
}


.backgroundTitles{
  border: 2px solid white;
  padding: 10px;
  padding-left: 140px;
  padding-right: 140px;
  background: rgba(0, 0, 0, 0.4);
}

.backgroundTitle{
  color: white;
  font-size: 40px;
}

.backgroundPs{
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: space-around;
  min-width: 100vw;
  
}

.backgroundPt {
  font-size: 24px;
  font-weight: bold;
}

.backgroundP {
  font-size: 24px;
  max-width: 50vw;
  max-height: 50vh;
}

.backgroundPsupr {
  font-size: 24px;
  max-width: 50vw;
  max-height: 50vh;
}

.backgroundFoot {
  font-size: 24px;
  max-width: 50vw;
  max-height: 50vh;
  color: white;
}



.backgroundPtctn {
  border: 2px solid white;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backgroundPtctn2 {
  border: 2px solid white;
  background: rgba(0, 0, 0, 0.4);
  max-width: 40vw;
}

.backgroundPtctn2supr {
  border: 2px solid white;
  background: rgba(0, 0, 0, 0.4);
  max-width: 40vw;
}

.backgroundPtctn3 {
  border: 2px solid white;
  background: rgba(0, 0, 0, 0.4);
  max-width: 40vw;
  padding: 20px;
}


.thirdTitle{
  font-size: 40px;
  color: white;
  text-shadow: 0.1em 0.1em 0.2em black;
}

.headband1 {
  background-color:white ;
  display: flex;
  justify-content:space-around;
  align-items: center;

}

.headband1p {
  color:  rgba(14, 148, 51);
  font-size: 24px;
}

.profilePic {
  max-height: 45vh;
  border: solid 2px white;
}

.footCtn {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100vw;
}


.form {
  width:40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
  
input, textarea {
  border: none;
  padding: 0.5rem;
  font-family: var(--secondary-font), Arial, Helvetica, sans-serif;
  width: 80%;
  height: 40%;
  transition: 200ms;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="text"]:not(output):not(:focus),
input[type="email"]:not(output):not(:focus),
textarea {
  border-bottom: 1px solid #fafafa;
  background: transparent;
  color: #fafafa;
  font-size: 1.5rem;
  box-shadow: none;
  outline: none;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="text"]:not(output):focus,
input[type="email"]:not(output):focus,
textarea:focus {
  border-bottom: 2px solid white;
}

input[type="submit"] {
  background: white;
  color:  rgba(14, 148, 51) ;
  margin-top: 1rem;
  width: auto;
  float: right;
}

input[type="submit"]:hover,
input[type="submit"]:focus {
  cursor: pointer;
  color: white;
  background:none;
  border: 2px solid white;
}

::placeholder {
  color: #fafafa;
}

.mail {
  font-weight:900;
  font-style: oblique;
}

.form-message {
  color:white;
  width: 100%;
  font-family: var(--secondary-font);
  font-weight: 700;
  padding: 15px;
  transition: 0.3ms ease-in-out;
}

.copy{
  color: white;
}

.Legal-container{
  top: 0;
  width: 100vw;
  background-image: url('https://github.com/florentdesmarets/Image-storage/blob/main/8-bit-gif-background/neat-desktop-with-stationery-side.jpg?raw=true');
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.legalMentions {
  display: flex;
  justify-content:space-around;
  text-align: center;
  flex-direction: column;
  height: 70vh;
}

.buttonBack {
  color: rgba(14, 148, 51) ;
}


.buttonBacks:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.buttonBacks:active {
  transform: scale(0.9);
  transition: all 0.2s ease-in-out;
}

.mentionTitle{
  margin-top: 0px;
  padding: 50px;
}

.loader{
  animation: charged 1s 3s forwards;
  animation-duration: 0.5s;
  background: white;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 16;
  top: 0px;
}

.txtdtl {
  color: white;
  font-size: 14px;
  font-style: italic;
}

@keyframes charged{
  0% {
       opacity: 1;
       background-position-x: 50%;
       height: 100%;
       overflow: hidden;
  }
  100% {
     opacity: 0;
     z-index: -1000;
     width: 0;
     height: 0;
     background-position-x: 0%;
   
  }
}

html, body { animation: noscroll 1s 4s forwards;}


@keyframes noscroll{
  0% {
       height: 100%;
       overflow: hidden;
  }
  100% {
    height: none;
    overflow: none;
  }
}

.spinerContainer {
  color: rgba(14, 148, 51)  ;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.spiner {
  width: 48px;
  height: 48px;
  border: 5px solid rgba(14, 148, 51);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}
.spiner:after {
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid rgba(14, 148, 51);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% { transform: translate(-50%, -50%) scale(0) }
  60% , 100% { transform: translate(-50%, -50%)  scale(1)}
}
@keyframes pulse {
  0% , 60% , 100%{ transform:  scale(1) }
  80% { transform:  scale(1.2)}
}

.points {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: block;
  margin:26px auto;
  position: relative;
  background: #FFF;
  box-shadow: -8px 0 #FFF, 8px 0 #FFF;
  box-sizing: border-box;
  animation: shadowPulse 1s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #FFF;
    box-shadow: -8px 0  rgba(14, 148, 51), 8px 0 #FFF;
  }
  66% {
    background:  rgba(14, 148, 51);
    box-shadow: -8px 0 #FFF, 8px 0 #FFF;
  }
  100% {
    background: #FFF;
    box-shadow: -8px 0 #FFF, 8px 0  rgba(14, 148, 51);
  }
}

.pointsContainer {
  display: flex;
}

.ptxt {
  margin-right: 16px;
}


@media screen and (max-width: 1500px) {
  .buttons {
    font-size: 0.6rem;
    margin: 0.6rem;
  }
}

@media screen and (max-width: 1400px) {
  .logo2 {
    width: 70px;
    height: 70px;
  }
  .backgroundTitle {
    font-size: 25px;
  }
}

@media screen and (max-width: 1250px) {
  .backgroundPt {
    font-size: 17px;
  }
  .backgroundP {
    font-size: 16px;
  }
}


@media screen and (max-width: 930px) {
   .backgroundPs{
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 80vh;
   }
   .profilePic{
    max-width: 40vw;
   }
   .backgroundPtctn{
    justify-content: center;
    max-width: 80vw;
   }
   .backgroundP {
    font-size: 24px;
    max-width: 100vw;
    max-height: 50vh;
   }
   .backgroundPtctn2{
    justify-content: center;
    max-width: 80vw;
   }
   .backgroundPtctn3{
    justify-content: center;
    max-width: 80vw;
   }
   .logo3 {
    display: none;
   }
   .footCtn {
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }
   .form {
    width: 80vw;
   }
   input[type="submit"] {
    background: white;
    color: rgba(14, 148, 51);
    margin-top: 1rem;
    width: auto;
    float: right;
    font-size: 20px;
  }
  input, textarea {
    border: none;
    padding: 2.0rem;
    font-family: var(--secondary-font), Arial, Helvetica, sans-serif;
    width: 80%;
    height: 40%;
    transition: 200ms;
}


}


@media screen and (max-width: 500px) {
  .backgroundPsupr {
    display: none;
  }
  .backgroundPtctn2supr {
    display: none;
  }

  .logo{
    width: 80px;
    margin-left: 1vw;
    margin-right: 1vw;
  }
  h1 {
    font-size: 1.3em;
  }
  .secondTitle {
    font-size: 0.6em;
  }
  .basic-burger{
    margin-left: 1vw;
    margin-right: 0;
  }
  .navbar {
    width: 100vw;
  }
  .backgroundTitles {
    padding: 10px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .headband1p {
    font-size: 16px;
  }
  .backgroundP {
    font-size: 16px;
  }
  .backgroundPt {
    font-size: 20px;
  }
  .backgroundFoot {
    font-size: 16px;
    max-width: 80vw;
  }
  .aos-init {
    opacity: 1 !important;
    transform: none !important;
    transition: none !important;
  }
  input, textarea {
    border: none;
    padding: 1.0rem;
    font-family: var(--secondary-font), Arial, Helvetica, sans-serif;
    width: 80%;
    height: 40%;
    transition: 200ms;
}
  
}
